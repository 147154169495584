import React from 'react';
import { css } from 'styled-components';

export const LinkContext = React.createContext(undefined);

export const LinkStyle = css`
  text-decoration: none;
  text-transform: uppercase;
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.main};
  font-size: ${({ theme }) => theme.fontSize.normal};
  transition: opacity 0.4s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
