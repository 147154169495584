import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { BackgroundImage } from '../BackgroundImage/backgroundImage.component';
import { TitleTypingEffect } from 'react-komponents';

export const MainBackground = () => {
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "place.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <SectionBackground>
      <BackgroundImage
        thumbnail={data.backgroundImage}
        opacity={0.4}
        backgroundColor={'rgb(48, 86, 163)'}
      />
      <Container>
        <TitleTypingEffect text="Tech Lead." delay={300} pause={5000} />
      </Container>
    </SectionBackground>
  );
};

const SectionBackground = styled.section`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
`;
