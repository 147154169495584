import styled, { css } from 'styled-components';

const getFontSize = (small, theme) => {
  if (small) return theme.fontSize.small;
  return theme.fontSize.normal;
};

export const TextStyle = ({ small, theme }) => css`
  font-family: ${theme.font.second};
  font-size: ${getFontSize(small, theme)};
  color: ${theme.colors.secondary};
`;

export const Text = styled.p`
  ${TextStyle}
`;
