import { MEDIA_DESKTOP } from '../constants';

export const lightTheme = {
  name: 'Light',
  section: {
    maxWidthInside: `${MEDIA_DESKTOP}px`,
  },
  navigationBar: {
    base: {
      height: '60px',
      backgroundColor: 'white',
      color: '#121212',
    },
    unScrolled: {
      height: '110px',
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  },
  spacing: {
    small: '4px',
    medium: '8px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '48px',
    section: '72px',
  },
  font: {
    brand: 'Montserrat',
    main: 'Montserrat',
    second: 'Open Sans',
  },
  colors: {
    brand: '#121212',
    primary: 'white',
    secondary: '#626262',
    textPrimary: '#121212',
    textSecondary: '#121212',
    sectionAlpha: '#ffffff',
    sectionBeta: '#fafafa',
    sectionCharlie: '#f0f0f0',
  },
  position: {
    front: 1000,
    between: 500,
    back: 0,
  },
  fontSize: {
    small: '14px',
    normal: '16px',
    large: '20px',
    xlarge: '24px',
    big: '44px',
  },
};
