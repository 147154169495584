import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LinkContext } from '../../elements/Link/link';
import { TextStyle } from '../../elements/Text/text';
import { Section } from '../../elements/Section/section';
import { MEDIA_TABLET } from '../../constants';

const InternalLink = ({ children, link }) => {
  const LinkNavigation = useContext(LinkContext);
  return (
    <TextLink>
      <LinkNavigation to={link}>{children}</LinkNavigation>
    </TextLink>
  );
};

InternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const ExternalLink = ({ children, href }) => {
  return (
    <TextLink>
      <Link href={href}>{children}</Link>
    </TextLink>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

const FooterSection = ({ key, children }) => {
  return <LinkSection key={key}>{children}</LinkSection>;
};

FooterSection.propTypes = {
  children: PropTypes.node.isRequired,
  key: PropTypes.string.isRequired,
};

export const Footer = ({ children, top, ...otherProps }) => (
  <Section {...otherProps}>
    <MainContainer>
      <TopContainer>{top}</TopContainer>
      <SectionContainer>{children}</SectionContainer>
    </MainContainer>
  </Section>
);

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  top: PropTypes.node.isRequired,
};

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LinkSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TextLink = styled.div`
  ${TextStyle}
  font-weight: 500;
  margin: ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 1;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none !important;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
`;

Footer.Section = FooterSection;
Footer.InternalLink = InternalLink;
Footer.ExternalLink = ExternalLink;
