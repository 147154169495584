import { useEffect, useState } from 'react';

export const HEADER_HEIGHT_THRESHOLD = 10;

export const useNavigationStatus = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const listener = () =>
      window.pageYOffset >= HEADER_HEIGHT_THRESHOLD
        ? setScrolled(true)
        : setScrolled(false);

    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return scrolled;
};
