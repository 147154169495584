import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${({ theme, fontFamily }) => fontFamily || theme.font.main};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.large};
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 25px;
  font-style: normal;
  white-space: normal;
  color: ${({ theme, color }) => color || theme.colors.textPrimary};
`;
