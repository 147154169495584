import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTypingDelay } from '../../hooks/useTypingDelay';

export const TitleTypingEffect = ({ text, delay, pause, className }) => {
  const displayText = useTypingDelay({ text, delay, pause });
  const result = `${displayText}|`;
  return <Title className={className}>{result}</Title>;
};

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-all;
  font-family: ${({ theme, fontFamily }) => fontFamily || theme.font.second};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.big};
  font-weight: 300;
  letter-spacing: 0.01em;
  font-style: normal;
  white-space: normal;
`;

TitleTypingEffect.defaultProps = {
  pause: 5000,
  className: undefined,
};

TitleTypingEffect.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  pause: PropTypes.number,
};
