import React from 'react';
import { NavigationBar } from 'react-komponents';
import { Logo } from '../Logo/logo.component';

export const Navigation = ({ location, dynamic }) => {
  return (
    <NavigationBar dynamic={dynamic}>
      {({ scrolled }) => (
        <>
          <NavigationBar.Section hideOnMobile>
            <NavigationBar.List justifyContent="flex-start">
              <Logo scrolled={dynamic && scrolled} />
            </NavigationBar.List>
          </NavigationBar.Section>
          <NavigationBar.Section>
            <NavigationBar.List justifyContent="flex-end">
              <NavigationBar.InternalLink
                active={location.pathname === '/'}
                link="/"
              >
                Accueil
              </NavigationBar.InternalLink>
              <NavigationBar.ExternalLink href="https://tech.julienkarst.com">
                Tech
              </NavigationBar.ExternalLink>
              <NavigationBar.InternalLink
                active={location.pathname === '/projects'}
                link="/projects"
              >
                Projets
              </NavigationBar.InternalLink>
              <NavigationBar.ExternalLink href="https://bit.ly/ContactJulienKarst">
                Contact
              </NavigationBar.ExternalLink>
            </NavigationBar.List>
          </NavigationBar.Section>
        </>
      )}
    </NavigationBar>
  );
};
