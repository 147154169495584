import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Paragraph } from '../Paragraph/paragraph';
import { LinkContext } from '../../elements/Link/link';

const DEFAULT_OVERLAY_OPACITY = 0.4;
const DEFAULT_OVERLAY_COLOR = 'black';
const DEFAULT_TEXT_COLOR = 'white';
const TEXT_SPACING = 'small';

export const CardImage = ({
  title,
  content,
  footer,
  renderImage,
  path,
  overlayColor,
  overlayOpacity,
  textColor,
  thumbnail,
}) => {
  const LinkNavigation = useContext(LinkContext);
  return (
    <MainContainer>
      <ParagraphContainer>
        <LinkNavigation to={path}>
          <Paragraph
            spacing={TEXT_SPACING}
            textColor={textColor}
            title={title}
            content={content}
            footer={footer}
          />
        </LinkNavigation>
      </ParagraphContainer>
      <MainImageContainer>
        <>
          <ImageContainer>{renderImage(thumbnail)}</ImageContainer>
          <ImageOverlay
            overlayColor={overlayColor}
            overlayOpacity={overlayOpacity}
          />
        </>
      </MainImageContainer>
    </MainContainer>
  );
};

CardImage.defaultProps = {
  title: null,
  content: null,
  footer: null,
  overlayColor: DEFAULT_OVERLAY_COLOR,
  overlayOpacity: DEFAULT_OVERLAY_OPACITY,
  textColor: DEFAULT_TEXT_COLOR,
};

CardImage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  footer: PropTypes.string,
  renderImage: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
  textColor: PropTypes.string,

  // It will be forwarded to your renderImage implementation, it can be a URL, object, image resources etc..
  // eslint-disable-next-line react/forbid-prop-types
  thumbnail: PropTypes.any.isRequired,
};

const MainContainer = styled.div`
  display: flex:
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
`;

const ParagraphContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  z-index: 1;
  display: flex;
  position: absolute;
  flex: 1;
  bottom: 0;
  left: 0;
`;

const MainImageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const ImageOverlay = styled.div.attrs(({ overlayColor, overlayOpacity }) => ({
  overlayColor,
  overlayOpacity,
}))`
  background-color: ${({ overlayColor }) => overlayColor};
  opacity: ${({ overlayOpacity }) => overlayOpacity};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  ${MainContainer}:hover & {
    transform: scale(1.25);
  }
`;
