import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

export const Logo = ({
  firstPart,
  secondPart,
  onClick,
  fontFamily,
  fontSize,
  color,
}) => (
  <LogoText
    onClick={onClick}
    pointer={!!onClick}
    fontFamily={fontFamily}
    fontSize={fontSize}
    color={color}
  >
    <LogoFirstPart>{firstPart}</LogoFirstPart>
    <LogoSecondPart>{secondPart}</LogoSecondPart>
  </LogoText>
);

Logo.defaultProps = {
  onClick: null,
};

Logo.propTypes = {
  firstPart: PropTypes.string.isRequired,
  secondPart: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fontFamily: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export const LogoWithTheme = withTheme(({ theme, ...otherProps }) => (
  <Logo
    color={theme.colors.brand}
    fontSize={theme.fontSize.large}
    fontFamily={theme.font.brand}
    {...otherProps}
  />
));

const LogoText = styled.p`
  text-decoration: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'none')};
  text-transform: uppercase;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  transition: color 0.4s ease;
`;

const LogoFirstPart = styled.span`
  font-weight: 600;
`;

const LogoSecondPart = styled.span`
  font-weight: 200;
`;
