import styled from 'styled-components';

export const Header = styled.nav`
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: ${({ scrolled, theme }) =>
    scrolled === false
      ? theme.navigationBar.unScrolled.height
      : theme.navigationBar.base.height};
  width: 100%;
  background-color: ${({ scrolled, theme }) =>
    scrolled === false
      ? theme.navigationBar.unScrolled.backgroundColor
      : theme.navigationBar.base.backgroundColor};
  transition: background 0.4s ease, height 0.4s ease;
  z-index: 1000;
`;
