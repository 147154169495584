import { useEffect, useState, useRef } from 'react';

const WAY = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const useTypingDelay = ({ text, delay, pause = delay }) => {
  const [displayText, setDisplayText] = useState(text);
  const [way, setWay] = useState(WAY.RIGHT);
  const wayRef = useRef(way);
  const [nextDelay, setNextDelay] = useState(delay);

  // Reset the current display text when the text change
  useEffect(() => {
    setDisplayText(text);
  }, [text]);

  // When the way change, update the ref
  useEffect(() => {
    wayRef.current = way;
  }, [way]);

  // Set the way based on the displayText
  useEffect(() => {
    if (displayText === text) setWay(WAY.LEFT);
    if (displayText === '') setWay(WAY.RIGHT);
  }, [displayText, text]);

  // Set the next delay based on the displayText
  useEffect(() => {
    if (wayRef.current === WAY.RIGHT && displayText.length === text.length)
      setNextDelay(pause);
    else setNextDelay(delay);
  }, [delay, displayText, pause, text]);

  // Update text with the delay
  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText(currentText => {
        if (wayRef.current === WAY.RIGHT) {
          const diff = text.replace(currentText, '');
          return `${currentText}${diff[0]}`;
        }
        return currentText.slice(0, -1);
      });
    }, nextDelay);
    return () => clearInterval(interval);
  }, [text, nextDelay]);

  return displayText;
};
