import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MEDIA_TABLET } from '../../constants';

const getSectionColor = (props, theme) => {
  if (props.alpha) return theme.colors.sectionAlpha;
  if (props.beta) return theme.colors.sectionBeta;
  if (props.charlie) return theme.colors.sectionCharlie;
  return theme.colors.sectionAlpha;
};

export const Section = ({ children, ...props }) => (
  <SectionContainer {...props}>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SectionContainer = styled.section`
  background-color: ${({ theme, ...otherProps }) =>
    getSectionColor(otherProps, theme)};
  padding: ${({ theme, noPadding }) => !noPadding && theme.spacing.section};
  @media (max-width: ${MEDIA_TABLET}px) {
    padding: ${({ theme, noPadding }) => !noPadding && theme.spacing.large};
  }
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const SectionContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: ${({ theme }) => theme.section.maxWidthInside};
  flex-direction: column;
`;
