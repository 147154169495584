import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { GlobalStyles, lightTheme } from 'react-komponents';

import Providers from '../providers';
import './layout.css';
import { MainBackground } from '../MainBackground/mainBackground.component';
import { Navigation } from '../Navigation/navigation.component';
import { Footer } from '../Footer/footer.component';

const ArticleGlobalStyle = createGlobalStyle`
  .post h1, h2, h3, h4, h5, h6, p, a {
    font-family: ${({ theme }) => theme.font.second};
    font-style: normal;
    white-space: normal;
  }
`;

// TODO: Inversion of colors in the navigation bar must be done in the same theme
export const Layout = ({ children }) => {
  return (
    <Providers>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <ArticleGlobalStyle />
        <MainContainer>
          <ContentContainer>{children}</ContentContainer>
          <Footer />
        </MainContainer>
      </ThemeProvider>
    </Providers>
  );
};

const MainContainer = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  flex-grow: 1;
`;

export const withHomeLayout = Component => ({ location, children }) => (
  <Component>
    <MainBackground />
    <Navigation location={location} dynamic />
    {children}
  </Component>
);

export const withPageLayout = Component => ({ location, children }) => (
  <Component>
    <Navigation location={location} />
    {children}
  </Component>
);

export const PageLayout = withPageLayout(Layout);
export const HomeLayout = withHomeLayout(Layout);
