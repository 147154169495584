import React, { createContext, useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Header } from '../../elements/Header/header';
import { LinkStyle, LinkContext } from '../../elements/Link/link';
import { useNavigationStatus } from '../../hooks/useNavigationStatus';
import { MEDIA_DESKTOP, MEDIA_TABLET } from '../../constants';
import { SectionContent } from '../../elements/Section/section';

export const NavigationBarContext = createContext(undefined);
export const useNavigationBarContext = () => useContext(NavigationBarContext);

const InternalLink = ({ link, children }) => {
  const LinkNavigation = useContext(LinkContext);
  const { scrolled } = useNavigationBarContext();

  return (
    <StyledLink scrolled={scrolled}>
      <LinkNavigation to={link} activeStyle={{ opacity: 1 }} active>
        {children}
      </LinkNavigation>
    </StyledLink>
  );
};

InternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const ExternalLink = ({ href, children }) => {
  const { scrolled } = useNavigationBarContext();

  return (
    <StyledLink scrolled={scrolled}>
      <StyledAnchor href={href}>{children}</StyledAnchor>
    </StyledLink>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export const NavigationBar = ({ children, dynamic }) => {
  const scrolledStatus = useNavigationStatus();
  const scrolled = dynamic ? scrolledStatus : true;

  return (
    <NavigationBarContext.Provider value={{ scrolled }}>
      <StyledHeader scrolled={scrolled}>
        <Container>{children({ scrolled })}</Container>
      </StyledHeader>
    </NavigationBarContext.Provider>
  );
};

NavigationBar.defaultProps = {
  dynamic: false,
};

NavigationBar.propTypes = {
  children: PropTypes.func.isRequired,
  dynamic: PropTypes.bool,
};

const List = styled.ul`
  display: flex;
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
`;

const StyledAnchor = styled.a`
  white-space: nowrap;
  text-decoration: inherit;
  font-family: inherit;
  ${LinkStyle}
  color: inherit;
`;

const StyledLink = styled.li`
  list-style-type: none;
  text-size-adjust: auto;
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-left: ${({ theme }) => theme.spacing.medium};
  color: ${({ scrolled, theme }) =>
    scrolled === false
      ? theme.navigationBar.unScrolled.color
      : theme.navigationBar.base.color};
`;

const StyledHeader = styled(Header)`
  display: flex;
  flex: 1;
  justify-content: center;
`;

// TODO: We need to replace this 144 value by 2x spacing.section
const Container = styled(SectionContent)`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${MEDIA_DESKTOP + 144}px) {
    margin-left: ${({ theme }) => theme.spacing.section};
    margin-right: ${({ theme }) => theme.spacing.section};
  }
  @media (max-width: ${MEDIA_TABLET}px) {
    justify-content: center;
    margin-left: ${({ theme }) => theme.spacing.xlarge};
    margin-right: ${({ theme }) => theme.spacing.xlarge};
  }
`;

const Section = styled.div`
  flex: 0.5;
  display: flex;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media (max-width: ${MEDIA_TABLET}px) {
        display: none;
      }
    `}

  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    css`
      display: none;
      @media (max-width: ${MEDIA_TABLET}px) {
        display: flex;
      }
    `}
`;

NavigationBar.Section = Section;
NavigationBar.List = List;
NavigationBar.InternalLink = React.memo(InternalLink);
NavigationBar.ExternalLink = React.memo(ExternalLink);
