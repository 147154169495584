import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from '../../elements/Text/text';
import { Title } from '../../elements/Title/title';

export const Paragraph = ({ title, content, footer, textColor, spacing }) => (
  <Container>
    {title && (
      <TitleText spacing={spacing} textColor={textColor}>
        {title}
      </TitleText>
    )}
    {content && (
      <Content spacing={spacing} textColor={textColor}>
        {content}
      </Content>
    )}
    {footer && (
      <Footer spacing={spacing} textColor={textColor} small>
        {footer}
      </Footer>
    )}
  </Container>
);

Paragraph.defaultProps = {
  textColor: undefined,
  spacing: undefined,
};

Paragraph.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  spacing: PropTypes.string,
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const TextProps = css`
  margin: ${({ theme, spacing }) =>
      spacing ? theme.spacing[spacing] : theme.spacing.medium}
    0;
  ${({ textColor }) => textColor && `color: ${textColor};`}
`;

const TitleText = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.03em;
  ${TextProps}
`;

const Content = styled(Text)`
  display: block;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 25px;
  font-style: normal;
  white-space: normal;
  ${TextProps}
`;

const Footer = styled(Text)`
  line-height: 21px;
  letter-spacing: 0.03em;
  ${TextProps}
`;
