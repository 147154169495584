import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { LinkContext } from '../../elements/Link/link';
import { Text } from '../../elements/Text/text';

export const Dropdown = ({ elements, text }) => {
  const LinkNavigation = useContext(LinkContext);

  const links = useMemo(
    () =>
      elements.map(({ title, link }) => (
        <LinkNavigation key={link} to={link}>
          {title}
        </LinkNavigation>
      )),
    [elements]
  );

  return (
    <MainContainer data-testid="dropdown">
      <Text>{text}</Text>
      <DropdownContainer>{links}</DropdownContainer>
    </MainContainer>
  );
};

Dropdown.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  text: PropTypes.string.isRequired,
};

const ShowDropdown = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const DropdownContainer = styled.div`
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.sectionAlpha};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing.large}
    ${({ theme }) => theme.spacing.large};
  z-index: 1;
`;

const MainContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropdownContainer} {
    ${ShowDropdown}
  }
`;
