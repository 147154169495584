import { MEDIA_DESKTOP } from '../constants';

export const darkTheme = {
  name: 'Dark',
  section: {
    maxWidthInside: `${MEDIA_DESKTOP}px`,
  },
  navigationBar: {
    base: {
      height: '60px',
      backgroundColor: '#121212',
      color: 'white',
    },
    unScrolled: {
      height: '110px',
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  },
  spacing: {
    small: '4px',
    medium: '8px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '48px',
    section: '72px',
  },
  font: {
    brand: 'Montserrat',
    main: 'Montserrat',
    second: 'Open Sans',
  },
  colors: {
    brand: '#121212',
    primary: '#ffffff',
    secondary: '#d2d2d2',
    textPrimary: '#ffffff',
    textSecondary: '#fafafa',
    sectionAlpha: '#121212',
    sectionBeta: '#222222',
    sectionCharlie: '#323232',
  },
  position: {
    front: 1000,
    between: 500,
    back: 0,
  },
  fontSize: {
    small: '14px',
    normal: '16px',
    large: '20px',
    xlarge: '24px',
    big: '44px',
  },
};
