import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const BackgroundImage = ({ thumbnail, opacity, backgroundColor }) => (
  <>
    <GatsbyImage
      image={thumbnail.childImageSharp.gatsbyImageData}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '100%',
      }}
      objectFit="cover"
    />
    <BackgroundImageOverlay
      backgroundColor={backgroundColor}
      opacity={opacity}
    />
  </>
);

const BackgroundImageOverlay = styled.div.attrs(
  ({ opacity, backgroundColor }) => ({
    opacity,
    backgroundColor,
  })
)`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
  position: absolute;
`;
