import styled from 'styled-components';

export const SectionTitle = styled.h2`
  font-family: ${({ theme, fontFamily }) => fontFamily || theme.font.second};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.big};
  font-weight: 300;
  letter-spacing: 0.01em;
  font-style: normal;
  white-space: normal;
  color: ${({ theme, color }) => color || theme.colors.textPrimary};
`;
