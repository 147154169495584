import React from 'react';
import { withTheme } from 'styled-components';
import { navigate } from 'gatsby-link';
import { LogoWithTheme } from 'react-komponents';

const navigateToHome = () => navigate('/');
export const Logo = withTheme(({ scrolled, theme }) => {
  return (
    <LogoWithTheme
      firstPart="Julien"
      secondPart="Karst."
      onClick={navigateToHome}
      color={
        scrolled === false
          ? theme.navigationBar.unScrolled.color
          : theme.navigationBar.base.color
      }
    />
  );
});
