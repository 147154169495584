import React from 'react';
import styled from 'styled-components';
import { Footer as FooterComponent, Banner, Text } from 'react-komponents';
import { Logo } from '../Logo/logo.component';
import PKG from '../../../package.json';

export const Footer = () => {
  return (
    <MainContainer>
      <FooterComponent top={<Logo />} alpha>
        <FooterComponent.Section key="Countries">
          <FooterComponent.InternalLink link="/">
            Home
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/blog/">
            Blog
          </FooterComponent.InternalLink>
          <FooterComponent.InternalLink link="/projects/">
            Portfolio
          </FooterComponent.InternalLink>
          <FooterComponent.ExternalLink href="https://tech.julienkarst.com">
            Tech
          </FooterComponent.ExternalLink>
        </FooterComponent.Section>
      </FooterComponent>
      <StyledBanner
        left={<StyledText>Made with ❤ in Nancy, Lorraine</StyledText>}
        right={
          <StyledText>
            {`Copyright © ${new Date().getFullYear()} by KARST DEVELOPMENT SARL. Version ${
              PKG.version
            }`}
          </StyledText>
        }
      />
    </MainContainer>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledBanner = styled(Banner)`
  background-color: black;
`;

const MainContainer = styled.div``;
