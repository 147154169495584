import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LinkContext, LinkStyle } from 'react-komponents';

const LinkStyled = styled(Link)`
  white-space: nowrap;
  text-decoration: inherit;
  font-family: inherit;
  ${({ active }) => active && LinkStyle}
  color: inherit;
`;

const Providers = ({ children }) => (
  <LinkContext.Provider value={LinkStyled}>{children}</LinkContext.Provider>
);

export default Providers;
