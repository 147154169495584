import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MEDIA_TABLET } from '../../constants';
import { SectionContent } from '../../elements/Section/section';

export const Banner = ({ left, center, right, className }) => (
  <BannerContainer className={className}>
    <SectionContainer>
      <Content>{left && left}</Content>
      <Content>{center && center}</Content>
      <Content>{right && right}</Content>
    </SectionContainer>
  </BannerContainer>
);

Banner.defaultProps = {
  left: undefined,
  center: undefined,
  right: undefined,
};

Banner.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  className: PropTypes.string.isRequired,
};

const BannerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.section};
  padding-right: ${({ theme }) => theme.spacing.section};
  @media (max-width: ${MEDIA_TABLET}px) {
    padding-left: ${({ theme }) => theme.spacing.large};
    padding-right: ${({ theme }) => theme.spacing.large};
  }
`;

const SectionContainer = styled(SectionContent)`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
